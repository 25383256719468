import AppInfo from "./helpers/appInfo";

const dev = {
  env: "dev",
  label: "Development",
  logger: true,
};

const test = {
  env: "test",
  label: "Test",
  logger: true,
};

const prod = {
  env: "prod",
  label: "Staging",
  logger: false,
};

const apis = {
  auth: {
    login: "/api/Account/Login", // POST
    logout: "/v1/auth/logout", // POST
    me: "/api/Account/User", // GET
    myAgency: "/api/Account/Agencies",
    refreshToken: "/v1/auth/refreshToken", // POST,
    sendResetPassword: "/api/Account/SendResetPassword",
    resetPassword: "/api/Account/ResetPassword",
    newToken: "/api/Account/token",
  },
  admin: {},
  customers: {
    agency: "/api/Agency", // GET
    businessType: "/api/Agency/BusinessTypes",
    approveContract: "api/Agency/:agencyId/Contracts/:contractId",
    editEconomicDetails:
      "/api/Agency/:agencyId/Contracts/:contractId/economics",
  },
  products: {
    families: "/api/Anagra/ProductFamilies",
    duration: "/api/Anagra/FinancialDurations",
    list: "/api/Agency/:agencyId/Products",
    accessories:
      "/api/Agency/:agencyId/ProductModel/:productModelId/Accessories",
    services: "/api/Agency/:agencyId/ProductModel/:productModelId/Services",
    createContract: "/api/Agency/:agencyId/Contracts",
    approveContract:
      "/api/Agency/:agencyId/Contracts/:contractId/Grenke/CancelDossier",
    prices: "/api/Agency/:agencyId/Contracts/prices",
    taxSavings: "/api/Agency/:agencyId/Contracts/tax-savings",
    catalog: "/api/Agency/:agencyId/CatalogXLS",
  },
  import: {
    importXLS: "/api/Import/XLS/",
    agencies: "/api/Agency",
    exportXLS: "/api/Agency/:agencyId/ContractsXLS",
  },
  creditSafe: "https://connect.creditsafe.com/v1",
  creditSafeVatNo: "https://connect.creditsafe.com/v1/companies?countries=IT",
  creditSafeFinancial: "https://connect.creditsafe.com/v1/companies",
  users: {
    user: "/api/User",
    agencies: "/api/Agency",
  },
  icecat: {
    completeInfo:
      "https://live.icecat.biz/api?shopname=openicecat-live&lang=IT&UserName=gciardo:codiceGTIN&app_key=yJc7YMhJ1B-8J27O_I3cXUuPkNpkVar3",
    content: "/api/Icecat",
    gallery: "/api/Icecat/gallery",
  },
  smartComSystem: "/api/smartComSystem",
  agencies: {
    agency: "/api/Agency",
    signers: "/api/Agency/SignersList",
    logo: "/api/Agency/:agencyId/logo",
  },
  orders: {
    list: "/api/Agency/:agencyId/Orders",
    export: "/api/Agency/contracts-orders",
    exportSerials: "/api/Agency/contracts-serials",
  },
  tickets: {
    list: "/api/Ticket",
    save: "/api/Ticket",
    delete: "/api/Ticket/:id",
  },
  stats: {
    stats: "/api/Statistics",
  },
  accessories: {
    charges: "/api/OneriAccessori",
  },
};

const env_config = function () {
  switch (AppInfo.env) {
    case "production":
      return prod;
    case "test":
      return test;
    case "development":
      return dev;
    default:
      return dev;
  }
};

const publicConfig: {
  start: string;
  env: string;
  label: string;
  logger: boolean;
  apiBaseurl: string;
  appBaseurl: string;
  defaultLocale: string;
  forceLocale?: string;
  enableLocales?: string[];
  localeCookieName: string;
  localeDictUrl?: string;
  firebaseConfig?: object;
} & {
  [x: string]: string | number | boolean | Date | JSON | JSON[];
} & typeof Config = {
  start: new Date().toISOString(),
  env: "dev",
  label: "Development",
  logger: false,
  defaultLocale: "it",
  localeCookieName: "locale",
  ...(window as any).config,
};

const Config = {
  app_name: AppInfo.name,
  app_version: AppInfo.version,
  maintenance_mode: false,
  roleScopes: {
    SUPERADMINISTRATOR: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete", "any"],
      contracts: ["create", "read", "update", "delete", "any"],
      importexcel: ["create", "read", "update", "delete", "any"],
      agencies: ["create", "read", "update", "delete", "any"],
      users: ["create", "read", "update", "delete", "any"],
      orders: ["create", "read", "update", "delete", "any"],
      tickets: ["create", "read", "update", "delete", "any"],
      stats: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    ADMINISTRATOR: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete", "any"],
      contracts: ["create", "read", "update", "delete", "any"],
      importexcel: ["create", "read", "update", "delete", "any"],
      users: ["create", "read", "update", "delete", "any"],
      tickets: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    AGENCY_ADMIN: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete"],
      contracts: ["create", "read", "update", "delete"],
      importexcel: ["create", "read", "update", "delete", "any"],
      users: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    AGENCY: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete"],
      contracts: ["create", "read", "update", "delete"],
      importexcel: ["create", "read", "update", "delete", "any"],
      users: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    CAPO_AREA: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete"],
      contracts: ["create", "read", "update", "delete"],
      users: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    GROUP_MANAGER: {
      products: ["create", "read", "update", "delete", "any"],
      customers: ["create", "read", "update", "delete"],
      contracts: ["create", "read", "update", "delete"],
      users: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    AGENT: {
      products: ["create", "read", "update", "delete"],
      customers: ["create", "read", "update", "delete"],
      contracts: ["create", "read", "update", "delete"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
    CUSTOMER: {
      products: ["create", "read", "update", "delete", "any"],
      contracts: ["create", "read", "update", "delete", "any"],
      tickets: ["create", "read", "update", "delete", "any"],
      blog: ["create", "read", "update", "delete", "any"],
      learning: ["create", "read", "update", "delete", "any"],
    },
  },
  regex: {
    validString: /.{1,}/,
    validNumber:
      /^\+?\d{2,4}?[-.\/\\\s]?\(?\d{1,3}?\)?[-.\/\\\s]?\d{1,4}[-.\/\\\s]?\d{1,4}[-.\/\\\s]?\d{1,9}$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password:
      /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-__+.]){1,}).{8,}$/,
    year: /^\d{4}$/g,
    postalCode: /^\d{5}$/,
    piva: /^[0-9]{11}$/,
    phone: { it: /^[0|3]{1}[0-9]{5,10}$/ },
    fiscalCode:
      /^([A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z])$/,
    iban: /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/,
  },
  apis,
  start: new Date().toISOString(),
};

export default { ...Config, ...publicConfig, ...env_config() };
