import ProductLayout from "layouts/products/products.layout";
import { lazy } from "react";
import { RouteType, type } from "../components/Router/Router";
import AdminLayout from "../layouts/admin/admin.layout";
import AppLayout from "../layouts/app/app.layout";
const OrdersPage = lazy(() => import("../pages/orders"));
const StatsSelectionPage = lazy(() => import("../pages/stats"));
const TypeSelectionPage = lazy(
  () => import("../pages/stats/pages/TypeSelectionPage"),
);
const DataVisualizationPage = lazy(
  () => import("../pages/stats/pages/DataVisualizationPage"),
);
const TicketsPage = lazy(() => import("../pages/tickets"));
const TicketForm = lazy(
  () => import("../pages/tickets/components/ticketDetails"),
);
const ProductSelectionPage = lazy(
  () => import("../pages/products/pages/ProductSelectionPage"),
);
const ModelSelectionPage = lazy(
  () => import("../pages/products/pages/ModelSelectionPage"),
);
const FamilySelectionPage = lazy(
  () => import("../pages/products/pages/FamilySelectionPage"),
);
const AccessoriesAndServicesPage = lazy(
  () => import("../pages/products/pages/AccessoriesAndServicesPage"),
);
const RecapCartPage = lazy(
  () => import("../pages/products/pages/RecapCartPage"),
);
const CustomerInfoPage = lazy(
  () => import("../pages/products/pages/CustomerInfoPage"),
);
const ShippingManagementPage = lazy(
  () => import("../pages/products/pages/ShippingManagementPage"),
);

const GeneralError = lazy(() => import("../helpers/ErrorBoundary"));
const Login = lazy(() => import("../pages/auth/login"));
const ChooseCompanyPage = lazy(() => import("../pages/auth/chooseCompany"));
const PasswordForgotPage = lazy(() => import("../pages/auth/forgotPassword"));
const PasswordResetPage = lazy(() => import("../pages/auth/resetPassword"));
const Forbidden = lazy(() => import("../pages/auth/forbidden"));
const CustomersPage = lazy(() => import("../pages/customers"));
const CustomerDetailPage = lazy(
  () => import("../pages/customers/components/detail"),
);
const ContractsPage = lazy(() => import("../pages/contracts"));
const ContractsDetailPage = lazy(
  () => import("../pages/contracts/components/contractDetail"),
);
const ExcelImportPage = lazy(() => import("../pages/import-excel"));
const UsersPage = lazy(() => import("../pages/users"));
const AgenciesPage = lazy(() => import("../pages/agencies"));

export const routes: RouteType[] = [
  {
    id: "login",
    path: "/login",
    titleLocalizationKey: "pages.login.title",
    component: Login,
    layout: AppLayout,
    type: type.AUTHENTICATION,
  },
  {
    id: "chooseCompany",
    path: "/choose-company",
    titleLocalizationKey: "pages.login.title",
    component: ChooseCompanyPage,
    layout: AppLayout,
    type: type.SECURED,
  },
  {
    id: "GeneralError",
    path: "/GeneralError",
    titleLocalizationKey: "general_error.title",
    component: GeneralError,
    // type: type.AUTHENTICATION,
    type: type.PUBLIC,
  },
  {
    id: "passwordForgot",
    path: "/forgot-password",
    titleLocalizationKey: "pages.login.title",
    component: PasswordForgotPage,
    layout: AppLayout,
    // redirectTo: "/",
    type: type.AUTHENTICATION,
  },
  {
    id: "passwordReset",
    path: "/recovery-password",
    titleLocalizationKey: "pages.reset_password.title",
    component: PasswordResetPage,
    layout: AppLayout,
    // redirectTo: "/",
    // type: type.AUTHENTICATION,
    type: type.AUTHENTICATION,
  },
  {
    id: "products",
    titleLocalizationKey: "pages.products.title",
    path: "/products",
    fallbackPath: "/customers",
    component: FamilySelectionPage,
    showInMenu: true,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "cart",
    titleLocalizationKey: "pages.cart.title",
    path: "/cart/accessories-and-services",
    component: AccessoriesAndServicesPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "cart",
    titleLocalizationKey: "pages.cart.title",
    path: "/cart/recap",
    component: RecapCartPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "cart",
    titleLocalizationKey: "pages.cart.title",
    path: "/cart/customer-info",
    component: CustomerInfoPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "cart",
    titleLocalizationKey: "pages.cart.title",
    path: "/cart/shipping-management",
    component: ShippingManagementPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "products",
    titleLocalizationKey: "pages.products.title",
    path: "/products/:familyId",
    component: ModelSelectionPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "products",
    titleLocalizationKey: "pages.products.title",
    path: "/products/:familyId/:modelId",
    component: ProductSelectionPage,
    showInMenu: false,
    layout: ProductLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "products",
  },
  {
    id: "customers",
    titleLocalizationKey: "pages.customers.title",
    path: "/customers",
    component: CustomersPage,
    showInMenu: true,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "customers",
  },
  {
    id: "customerDetail",
    titleLocalizationKey: "pages.customers.title",
    path: "/customers/:customerId",
    component: CustomerDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: false,
    },
    section: "customers",
  },
  // {
  //   id: "contractDetail",
  //   titleLocalizationKey: "pages.customers.title",
  //   path: "/customers/:customerId/:contractId",
  //   component: ContractDetailPage,
  //   showInMenu: false,
  //   layout: AdminLayout,
  //   type: type.SECURED,
  //   layoutOptions: {
  //     hideOnBack: false,
  //   },
  //   section: "customers",
  // },
  {
    id: "contracts",
    titleLocalizationKey: "pages.contracts.title",
    path: "/contracts",
    component: ContractsPage,
    showInMenu: true,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "contracts",
  },
  {
    id: "users",
    titleLocalizationKey: "pages.users.title",
    path: "/users",
    component: UsersPage,
    showInMenu: true,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "users",
  },
  {
    id: "agencies",
    titleLocalizationKey: "pages.agencies.table_name",
    path: "/agencies",
    component: AgenciesPage,
    showInMenu: true,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "agencies",
  },
  {
    id: "contractsDetail",
    titleLocalizationKey: "pages.contracts.title",
    path: "/contracts/:contractId",
    component: ContractsDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: false,
    },
    section: "contracts",
  },
  {
    id: "homepage",
    titleLocalizationKey: "pages.home.title",
    path: "/",
    // additionalPaths: ["/products"],
    redirectTo: "/products",
  },
  {
    id: "forbidden",
    path: "/forbidden",
    titleLocalizationKey: "pages.forbidden.title",
    component: Forbidden,
    type: type.PUBLIC,
  },
  {
    id: "importexcel",
    path: "/importexcel",
    titleLocalizationKey: "pages.import_file.title",
    component: ExcelImportPage,
    layout: AdminLayout,
    showInMenu: true,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "importexcel",
  },
  {
    id: "orders",
    path: "/orders",
    titleLocalizationKey: "pages.orders.title",
    component: OrdersPage,
    layout: AdminLayout,
    showInMenu: true,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "orders",
  },
  {
    id: "stats",
    path: "/stats",
    titleLocalizationKey: "pages.stats.title",
    component: StatsSelectionPage,
    layout: AdminLayout,
    showInMenu: true,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "stats",
  },
  {
    id: "stats",
    path: "/stats/:category",
    titleLocalizationKey: "pages.stats.title",
    component: TypeSelectionPage,
    layout: AdminLayout,
    showInMenu: false,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "stats",
  },
  {
    id: "stats",
    path: "/stats/:category/:id",
    titleLocalizationKey: "pages.stats.title",
    component: DataVisualizationPage,
    layout: AdminLayout,
    showInMenu: false,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "stats",
  },
  {
    id: "tickets",
    path: "/tickets",
    titleLocalizationKey: "pages.tickets.title",
    component: TicketsPage,
    layout: AdminLayout,
    showInMenu: true,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "tickets",
  },
  {
    id: "ticketDetail",
    titleLocalizationKey: "pages.tickets.title",
    path: "/tickets/:customerId/:productId",
    component: TicketForm,
    showInMenu: false,
    layout: AdminLayout,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: false,
    },
    section: "tickets",
  },
  {
    id: "blog",
    titleLocalizationKey: "pages.blog.title",
    path: "https://blog.tdsynnex.it/news-mondo-apple",
    showInMenu: true,
    type: type.SECURED,
    section: "blog",
  },
  {
    id: "learning",
    titleLocalizationKey: "pages.learning.title",
    path: "https://blog.tdsynnex.it/apple-learning-td-synnex",
    showInMenu: true,
    type: type.SECURED,
    section: "learning",
  },
];
